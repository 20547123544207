import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://tellus-test-api.organicresponse.com',
  apiPath: '/api/v1',
  frontendUrl: 'https://tellus-test.organicresponse.com',
  features: {
    'Tenant Roles': true,
    'Tenant Groups': true,
    DDL: true
  },
  queryMicroPath: 'analytics',
  elmtPollingInterval: 5000
};
